import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import { ThemeProps } from '../theme/ThemeProps'
import { buildClasses, buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, {
    FocusEvent,
    ForwardedRef,
    InputHTMLAttributes,
    ReactNode,
    Ref,
    forwardRef,
    useId,
    useMemo,
    useState
} from 'react'
import Tooltip, { TooltipRef } from '../tooltip/Tooltip'

type TextFieldType = 'text' | 'password' | 'email' | 'number' | 'date' | 'file'

type TextFieldSize = 'sm' | 'md'

export type TextFieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'id' | 'size'> &
    ThemeProps & {
        label?: string | ReactNode
        subtitle?: string | ReactNode
        type?: TextFieldType
        valid?: boolean
        size?: TextFieldSize
        icon?: ReactNode
        rightIcon?: ReactNode
        message?: ReactNode
        renderMessageInTooltip?: boolean
        tooltipRef?: Ref<TooltipRef>
        removePaddingTop?: boolean
        srOnlyLabel?: boolean
        forceReadOnly?: boolean
        inputClassName?: string
        disableReadOnlyStyle?: boolean
        enableRightIconPointerEvents?: boolean
        enableLeftIconPointerEvents?: boolean
        enableOnFocusSelect?: boolean
        isRequired?: boolean
        compact?: boolean
    }

const TextField = (
    {
        type = 'text',
        label,
        subtitle,
        valid = true,
        color = 'primary',
        size = 'md',
        className,
        inputClassName,
        icon,
        rightIcon,
        message,
        renderMessageInTooltip,
        removePaddingTop,
        srOnlyLabel,
        tooltipRef,
        forceReadOnly,
        disableReadOnlyStyle,
        enableRightIconPointerEvents,
        enableLeftIconPointerEvents,
        enableOnFocusSelect,
        isRequired = false,
        compact = false,
        ...props
    }: TextFieldProps,
    ref: ForwardedRef<HTMLInputElement>
) => {
    const randomId = useId()
    const [showPassword, setShowPassword] = useState<boolean>(false)

    const renderTogglePassword = () => {
        if (type === 'password') {
            return (
                <button
                    className='absolute right-1 top-0 flex items-center h-full p-1'
                    type='button'
                    onClick={() => {
                        return setShowPassword(prev => {
                            return !prev
                        })
                    }}
                >
                    {showPassword ? (
                        <EyeIcon className='h-5 w-5 text-gray-600' />
                    ) : (
                        <EyeOffIcon className='h-5 w-5 text-gray-600' />
                    )}
                </button>
            )
        }
    }

    const inputType = useMemo<TextFieldType>(() => {
        if (type === 'password') {
            return showPassword ? 'text' : 'password'
        }
        return type
    }, [showPassword, type])

    const renderLabel = () => {
        return (
            (label || props.placeholder) && (
                <label htmlFor={randomId} className={buildClasses({ 'sr-only': !label || srOnlyLabel })}>
                    {label || props.placeholder}
                    {subtitle && <small>{subtitle}</small>}
                </label>
            )
        )
    }

    const renderRequiredLabel = () => {
        return <p className='text-red text-lg'>*</p>
    }

    const renderIcon = () => {
        return (
            icon && (
                <span
                    className={buildClassesWithDefault(
                        {
                            'pointer-events-none': !enableLeftIconPointerEvents
                        },
                        'left-icon'
                    )}
                >
                    {icon}
                </span>
            )
        )
    }

    const renderRightIcon = () => {
        return (
            rightIcon && (
                <span
                    className={buildClassesWithDefault(
                        {
                            'pointer-events-none': !enableRightIconPointerEvents
                        },
                        'right-icon'
                    )}
                >
                    {rightIcon}
                </span>
            )
        )
    }

    const renderMessage = () => {
        return message && <span className='text-field-message'>{message}</span>
    }

    const containerClassName = buildClassesWithDefault(
        {
            invalid: !valid,
            [color]: true,
            [size]: true,
            'text-field': true,
            compact,
            icon: !!icon,
            '!pt-0': removePaddingTop,
            'has-right-icon': !!rightIcon,
            'disable-read-only-style': disableReadOnlyStyle
        },
        className
    )

    const onFocus = (event: FocusEvent<HTMLInputElement>) => {
        if (enableOnFocusSelect) {
            event.target.select()
        }
        props?.onFocus?.(event)
    }

    const renderInput = (additionalRef?: Ref<HTMLInputElement>, additionalProps: Record<string, unknown> = {}) => {
        return (
            <>
                <div {...additionalProps} ref={additionalRef} className='relative'>
                    <input
                        {...props}
                        className={inputClassName}
                        type={inputType}
                        ref={ref}
                        id={randomId}
                        onFocus={onFocus}
                        autoComplete={props.autoComplete || 'off'}
                        readOnly={forceReadOnly || props.readOnly}
                        data-test-element-type={type}
                        data-test-name={props.name}
                        data-test-element='input'
                    />
                    {renderIcon()}
                    {renderRightIcon()}
                    {renderTogglePassword()}
                </div>
                {!renderMessageInTooltip && renderMessage()}
            </>
        )
    }

    return (
        <div className={containerClassName}>
            <div className='flex flex-row'>
                {renderLabel()} {isRequired && renderRequiredLabel()}
            </div>
            <div className='relative'>
                <div className='flex flex-col'>
                    {renderMessageInTooltip ? (
                        <Tooltip
                            content={message}
                            backgroundColor={!valid ? 'danger.DEFAULT' : 'primary.DEFAULT'}
                            textColor='white'
                            ref={tooltipRef}
                            disabled={!renderMessageInTooltip || !message}
                            lazy={false}
                        >
                            {renderInput}
                        </Tooltip>
                    ) : (
                        renderInput()
                    )}
                </div>
            </div>
        </div>
    )
}

export default forwardRef(TextField)
