import { HTMLAttributes, PropsWithChildren, useId } from 'react'
import { PostCardDescriptionProps } from './PostCardDescription'
import { PostCardImageProps } from './PostCardImage'
import { PostCardTitleProps } from './PostCardTitle'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import { findChildrenElement } from '../../../utils/Helper'

export type PostCardProps = HTMLAttributes<HTMLDivElement> & {
    clickable?: boolean
}

const PostCard = ({ clickable, className, children, ...props }: PropsWithChildren<PostCardProps>) => {
    const randomId = useId()

    const renderImage = () => {
        return findChildrenElement<PostCardImageProps>(children, 'PostCardImage')
    }

    const renderTitle = () => {
        return findChildrenElement<PostCardTitleProps>(children, 'PostCardTitle')
    }

    const renderDescription = () => {
        return findChildrenElement<PostCardDescriptionProps>(children, 'PostCardDescription')
    }

    const containerClasses = {
        'post-card': true,
        'cursor-pointer': !!clickable,
        'w-full': true,
        'shadow-primary-card': true
    }

    return (
        <div key={randomId} className={buildClassesWithDefault(containerClasses, className)} {...props}>
            {renderImage()}
            <div className={buildClassesWithDefault({ 'post-card-container': true })}>
                <div className={buildClassesWithDefault(['flex-1'])}>
                    <a className={buildClassesWithDefault(['block', 'mt-2'])}>
                        {renderTitle()}
                        {renderDescription()}
                    </a>
                </div>
            </div>
        </div>
    )
}

PostCard.displayName = 'PostCard'

export default PostCard
